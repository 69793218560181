import React, { useState } from "react";
import "./styles.css";

export default function App() {
  const [userChoice, setUserChoice] = useState("-");
  const [computerChoice, setComputerChoice] = useState("-");
  const [userScore, setUserScore] = useState(0);
  const [computerScore, setComputerScore] = useState(0);
  const [result, setResult] = useState(null);
  const choices = ["Rock", "Paper", "Scissors"];

  const handleTurn = (choice) => {
    const randomNum = Math.floor(Math.random() * 3);
    setUserChoice(choice);
    setComputerChoice(choices[randomNum]);
    if (choice === choices[randomNum]) {
      setResult("It's a tie!");
    } else if (
      choices.indexOf(choice) ===
      (choices.indexOf(choices[randomNum]) + 1) % 3
    ) {
      setUserScore(userScore + 1);
      setResult("You win!");
    } else {
      setComputerScore(computerScore + 1);
      setResult("Computer wins!");
    }
  };

  return (
    <div className="App">
      <div className="buttons">
        <button className="rounded-button" onClick={() => handleTurn("Rock")}>
          <i className="fa-regular fa-hand-back-fist"></i>
        </button>
        <button className="rounded-button" onClick={() => handleTurn("Paper")}>
          <i className="fa-regular fa-hand"></i>
        </button>
        <button
          className="rounded-button"
          onClick={() => handleTurn("Scissors")}
        >
          <i className="fa-regular fa-hand-scissors"></i>
        </button>
      </div>

      <div className="choice-text">
        <p>Your choice: {userChoice}</p>
        <p>Computer's choice: {computerChoice}</p>
      </div>
      <div className="result-text">
        <div>{result}</div>
      </div>
      <div className="score-text">
        <div>Your score: {userScore}</div>
        <div>Computer score: {computerScore}</div>
      </div>
    </div>
  );
}
